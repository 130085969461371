<template>
  <div class="question-container">
    <div class="question-header">
      <h3>Question {{ questionNumber }} of {{ totalQuestions }}</h3>
    </div>

    <div class="question-content">
      <h4>{{ currentQuestion.question }}</h4>

      <!-- Multiple Choice -->
      <div v-if="currentQuestion.type === 'multi-select'" class="options">
        <div v-for="(option, index) in currentQuestion.options" :key="index" class="option">
          <input 
            type="checkbox" 
            :id="'option-' + index"
            :checked="Array.isArray(currentAnswer) && currentAnswer.includes(option)"
            @change="(e) => {
              if (!Array.isArray(currentAnswer)) this.currentAnswer = []
              if (e.target.checked) {
                currentAnswer.push(option)
              } else {
                const index = currentAnswer.indexOf(option)
                if (index > -1) currentAnswer.splice(index, 1)
              }
            }"
          >
          <label :for="'option-' + index">{{ option }}</label>
        </div>
      </div>

      <!-- Single Select -->
      <div v-else-if="currentQuestion.type === 'single-select'" class="options">
        <div v-for="(option, index) in currentQuestion.options" :key="index" class="option">
          <input 
            type="radio" 
            :id="'option-' + index"
            :value="option"
            v-model="currentAnswer"
            name="single-select"
          >
          <label :for="'option-' + index">{{ option }}</label>
        </div>
      </div>

      <!-- Text Answer -->
      <div v-else class="text-answer">
        <textarea 
          v-model="currentAnswer"
          :rows="currentQuestion.type === 'long-text' ? 6 : 3"
          placeholder="Type your answer here... (be specific and detailed in your response)"
        ></textarea>
      </div>

      <div class="button-container">
        <button 
          @click="goBack" 
          class="btn-secondary"
          v-if="questionNumber > 1"
        >
          Previous Question
        </button>
        <button @click="submitAnswer" class="btn-primary">
          {{ questionNumber === totalQuestions ? 'Finish Quiz' : 'Next Question' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizComponent',
  props: {
    currentQuestion: {
      type: Object,
      required: true
    },
    questionNumber: {
      type: Number,
      required: true
    },
    totalQuestions: {
      type: Number,
      required: true
    },
    answers: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentAnswer: null
    }
  },
  methods: {
    submitAnswer() {
      this.$emit('answer-submitted', this.currentAnswer)
    },
    goBack() {
      this.$emit('go-back')
    }
  },
  watch: {
    currentQuestion: {
      immediate: true,
      handler(newQuestion) {
        // Get saved answer for this question
        const savedAnswer = this.answers[this.questionNumber - 1]
        this.currentAnswer = savedAnswer !== undefined ? savedAnswer : 
          newQuestion.type === 'multi-select' ? [] : ''
      }
    }
  }
}
</script>

<style scoped>
.question-container {
  margin: 20px 0;
  padding: 30px;
  @extend .glass-panel;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 768px) {
    padding: 20px;
    margin: 10px 0;
  }
}

.question-container::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(106, 90, 205, 0.1), transparent 70%);
  opacity: 0.5;
  pointer-events: none;
}

.options {
  margin: 20px 0;
  display: grid;
  gap: 12px;
}

.option {
  padding: 12px;
  background: rgba(106, 90, 205, 0.1);
  border-radius: 6px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
}

.option:hover {
  background: rgba(106, 90, 205, 0.2);
  transform: translateX(4px);
}

.option input[type="checkbox"],
.option input[type="radio"] {
  margin-right: 12px;
  width: 18px;
  height: 18px;
  accent-color: var(--accent-purple);
}

textarea {
  @extend .modern-input;
  resize: vertical;
  min-height: 120px;
  width: calc(100% - 32px);
  font-size: 16px;
  line-height: 1.6;
  font-family: inherit;
  
  @media (max-width: 768px) {
    width: 100%;
    min-height: 100px;
    font-size: 14px;
  }
}

textarea::placeholder {
  color: var(--neutral-gray);
  opacity: 0.7;
  font-style: italic;
}

.question-header {
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.question-content h4 {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: var(--accent-lavender);
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
}

.button-container button {
  @media (max-width: 768px) {
    width: 100%;
    margin: 5px 0;
  }
}

@media (min-width: 769px) {
  .button-container button:last-child {
    margin-left: auto;
  }
}
</style> 