<template>
  <div class="registration-form">
    <h2>Please Register to Start</h2>
    <form @submit.prevent="submitForm" class="registration-form">
      <div class="form-group">
        <label for="name">Full Name:</label>
        <input 
          type="text" 
          id="name" 
          v-model="formData.name" 
          required
        >
      </div>

      <div class="form-group">
        <label for="email">Email:</label>
        <input 
          type="email" 
          id="email" 
          v-model="formData.email" 
          required
        >
        <div v-if="emailError" class="error-message">
          {{ emailError }}
        </div>
      </div>

      <div class="form-group">
        <label for="experience">Years of Experience Running Ads:</label>
        <input 
          type="number" 
          id="experience" 
          v-model="formData.experience" 
          min="0"
          required
        >
      </div>

      <button 
        type="submit" 
        class="btn-primary"
        :disabled="isSubmitting"
      >
        {{ isSubmitting ? 'Verifying...' : 'Start Quiz' }}
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'RegistrationForm',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        experience: ''
      },
      emailError: '',
      isSubmitting: false
    }
  },
  methods: {
    async submitForm() {
      if (this.isSubmitting) return;
      
      this.emailError = ''
      this.isSubmitting = true

      const completedEmails = JSON.parse(localStorage.getItem('completed_quiz_emails') || '[]')
      if (completedEmails.includes(this.formData.email)) {
        this.emailError = 'This email has already been used to complete the quiz. Only one submission per email is allowed.'
        this.isSubmitting = false
        return
      }

      try {
        // Execute reCAPTCHA
        const token = await this.executeRecaptcha()
        
        // Verify token on your server or proceed if valid
        if (token) {
          this.$emit('start-quiz', this.formData)
        } else {
          this.emailError = 'Verification failed. Please try again.'
        }
      } catch (error) {
        console.error('Captcha error:', error)
        this.emailError = 'Verification failed. Please try again.'
      } finally {
        this.isSubmitting = false
      }
    },

    executeRecaptcha() {
      return new Promise((resolve, reject) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute('6Lf_fJkqAAAAACiS9J3arfp3RmDfbQHN9JviLPu2', { action: 'submit' })
            .then(token => {
              resolve(token)
            })
            .catch(error => {
              reject(error)
            })
        })
      })
    }
  }
}
</script>

<style scoped>
.registration-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
  @extend .glass-panel;
  position: relative;
  z-index: 1;
  text-align: center;
  
  @media (max-width: 768px) {
    padding: 20px;
    width: 100%;
    max-width: none;
  }
}

.registration-form::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, 
    rgba(255, 111, 97, 0.1), 
    rgba(106, 90, 205, 0.1)
  );
  z-index: -1;
  border-radius: 12px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
  text-align: left;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 15px;
  }
}

label {
  display: block;
  margin-bottom: 5px;
  color: var(--neutral-gray);
  font-weight: 500;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  width: 100%;
}

input {
  @extend .modern-input;
  width: 320px;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    width: 100%;
    font-size: 16px;
  }
}

h2 {
  text-align: center;
  margin-bottom: 30px;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.8em;
}

button {
  margin-top: 30px;
  width: 320px;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
}

.error-message {
  color: var(--accent-pink);
  font-size: 0.9em;
  margin-top: 5px;
  text-align: left;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style> 